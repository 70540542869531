import axios from "axios";

import AuthService from '../Auth/AuthService';

export default class BatidaService extends AuthService {

  listarServidorPeriodo(codigo,data1,data2) {
    return axios({
      method: 'get',
      url: this.api_url + 'batida/periodo/' + codigo + '/' +data1 + '/' + data2,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }

  listarServidorMensal(codigo,ano,mes) {
    return axios({
      method: 'get',
      url: this.api_url + 'batida/mensal/' + codigo + '/' +ano + '/' + mes,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }

  listarServidorDiario(codigo,data) {
    return axios({
      method: 'get',
      url: this.api_url + 'batida/' + codigo + '/' + data,
      withCredentials: true,
      headers: {
        'Authorization': 'Bearer ' + this.token(),
      },
    }).then(res => res.data);
  }

  controleDeAcessoDiario(data) {
    return axios({
      method: 'post',
      url: this.api_url + 'batida',
      data: data,
    }).then(res => res.data);
  }
  relatorioMensal(data){
    return axios({
      method: 'POST',
      url: this.api_url + 'batida/public-mensal',
      data: data,
    }).then(res => res.data);
  }
  
}
