<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card">
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-7">
                <label for="pessoa">Informe a Pessoa</label>
                <Dropdown
                  v-model="selectedPessoa"
                  :options="pessoas"
                  :filter="true"
                  :showClear="true"
                  optionValue="id"
                  optionLabel="nome"
                  placeholder="Selecione um Servidor"
                  class="p-mr-2 p-mb-2"
                />
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="data">Data</label>
                <InputText
                  type="date"
                  v-model="data"
                  required="true"
                  class="p-mr-2 p-mb-2"
                />
              </div>
              <div class="p-field p-col-12 p-md-1">
                <br />
                <Button
                  icon="pi pi-search"
                  style="margin-top: 7px"
                  @click="listarRegistroBatida()"
                />
              </div>
            </div>
          </template>
          <template v-slot:right>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help p-mb-2"
              :disabled="!registros"
              @click="exportCSV($event)"
          /></template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="registros"
          class="p-datatable-sm p-datatable-striped"
          v-if="apresentar"
          dataKey="0"
        >
          <template #header>
            <div class="p-fluid p-formgrid p-grid" id="dadosPonto">
              <div class="p-field p-col-12 p-md-12">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-12 p-md-2">
                    <div class="p-d-flex p-ai-center p-jc-center ctisDiv">
                      <img
                        src="assets/layout/images/sespa.png"
                        alt="SESPA"
                        width="230"
                        class="ctis"
                        style="margin-top: 45px"
                      />
                    </div>
                  </div>

                  <div class="p-col-12 p-md-9" v-if="pessoa[0]">
                    <label>Nome: {{ pessoa[0][0] }}</label
                    ><br />
                    <label>Matricula: {{ pessoa[0][1] }}</label
                    ><br />
                    <label v-if="pessoa[0][3]">Cargo: {{ pessoa[0][3] }}</label>
                    <label v-else>Cargo: SEM CARGO</label><br />

                    <label v-if="pessoa[0][4]"
                      >Função: {{ pessoa[0][4] }}.</label
                    >
                    <label v-else>Função: SEM FUNÇÃO</label> <br />
                    <label>Atuação: {{ pessoa[0][5] }}</label
                    ><br />
                    <label>Situação: {{ pessoa[0][2] }}</label
                    ><br />
                    <label>Horario: {{ pessoa[0][6] }}</label>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>
          <Column field="0" header="Equipamento" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Equipamento</span>
              {{ slotProps.data[0] }}
            </template>
          </Column>
          <Column field="1" header="N° Registro" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">N° Registro</span>
              {{ slotProps.data[1] }}
            </template>
          </Column>
          <Column field="2" header="Resposta" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Resposta</span>
              {{ slotProps.data[2] }}
            </template>
          </Column>
          <Column field="3" header="Batida Data/Hora" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Batida Data/Hora</span>
              {{ formatarHora(slotProps.data[3]) }}
            </template>
          </Column>
          <Column field="4" header="Evento" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Data/Hora</span>
              <span
                v-if="slotProps.data[4] === 1"
                class="product-badge status-Entrada"
                >Entrada</span
              >
              <span
                v-if="slotProps.data[4] === 2"
                class="product-badge status-Saída"
                >Saída</span
              >
            </template>
          </Column>
          <Column field="5" header="Data da Importação" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Data/Hora Batida</span>
              {{ formatarHora(slotProps.data[5]) }}
            </template>
          </Column>
          <template #footer>Horas Cumpridas : {{ horasTotal }} </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from "moment";
import AuthService from "@/service/Auth/AuthService";

import PessoaService from "@/service/Pessoa/PessoaService";
import BatidaService from "@/service/Batida/BatidaService";

export default {
  data() {
    return {
      pessoa: {
        nome: null,
        mf: null,
        situacao: null,
        cargo: null,
        funcao: null,
        atuacao: null,
        horario: null,
      },
      pessoas: null,
      selectedPessoa: null,
      data: null,
      registros: null,
      horasTotal: null,
      apresentar: false,
    };
  },
  authService: null,
  pessoaService: null,
  batidaService: null,
  created() {
    this.authService = new AuthService();
    this.pessoaService = new PessoaService();
    this.batidaService = new BatidaService();
  },
  mounted() {
    this.listPessoas();
  },
  methods: {
    formatarHora(date) {
      if (date) {
        return Moment(date).utc().format("H:mm:ss");
      }
      return;
    },
    listPessoas() {
      this.pessoaService.listar().then((data) => {
        this.pessoas = data;
      });
    },
    listarRegistroBatida() {
      if (this.selectedPessoa !== null && this.data !== null) {
        this.batidaService
          .listarServidorDiario(this.selectedPessoa, this.data)
          .then((data) => {
            if (data.length == 0) {
              this.apresentar = false;
              alert(
                "Não foram encontrado registros de batidas para data " +
                  Moment(this.data).utc().format("DD/MM/YYYY") +
                  "."
              );
            } else {
              this.registros = data;
              this.horasCumpridas();
              this.listOcorrenciasPessoa(this.selectedPessoa);
              this.apresentar = true;
            }
          });
      } else {
        alert("Campo de pessoa e data são obrigatórios.");
      }
    },
    exportCSV() {
      this.$refs.dt.exportCSV(this.registros);
    },
    horasCumpridas() {
      let primeiroDia = this.formatarHora(this.registros[0][3]);
      let ultimoDia = this.formatarHora(
        this.registros[this.registros.length - 1][3]
      );
      /*
      let dtChegada = this.formatarHora(this.registros[0][3]);
      var dtPartida = this.formatarHora(this.registros[1][3]);
      */
      let ms = Moment(ultimoDia, "HH:mm:ss").diff(
        Moment(primeiroDia, "HH:mm:ss")
      );
      let d = Moment.duration(ms);
      let s = Math.floor(d.asHours()) + Moment.utc(ms).format(":mm:ss");
      this.horasTotal = s;
    },
    listOcorrenciasPessoa(codigo) {
      this.pessoaService.listarOcorrencias(codigo).then((data) => {
        this.pessoa = data;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.img-fluid {
  max-width: 100%;
  height: auto;
}
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.visitante-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.p-dialog .visitante-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }
      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: left;
        }
        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-Entrada {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-Saída {
    background: #ffcdd2;
    color: #c63737;
  }
}
</style>